.section-bg{
	background: rgba(241, 242, 245, 1);
}
.section1{
	padding-bottom: 99px;
}
.section-title3{
	font-size: 30px;
	color: rgba(89, 89, 97, 1);
	text-align: center;
	padding-top: 4px;
	line-height: 198px;
	font-weight: normal;
}
.function-list{
	width: 100%;
}
.function-item{
	float: left;
	width: 380px;
	height: 150px;
	box-shadow:0px 6px 24px 0px rgba(225,226,230,1);
	border-radius:10px;
	margin-right: 30px;
	margin-bottom: 40px;
	padding: 25px 22px 25px 10px;
	background: #fff;
}
.function-item:nth-child(3n+3){
	margin-right: 0;
}
.function-item:nth-last-child(-n+3){
	margin-bottom: 0;
}
.function-img{
	float: left;
	width: 100px;
	height: 100px;
}
.function-info{
	float: left;
	margin-left: 10px;
	width: 238px;
}
.function-title{
	font-size:20px;
	color:rgba(70,72,77,1);
	line-height: 32px;
	letter-spacing: 2px;
}
.function-text{
	color: rgba(144, 146, 153, 1);
	font-size: 14px;
	margin-top: 7px;
}
.section-block{
	height: 514px;
}
.section-block:nth-child(odd){
	background-color: #fff;
}
.section-block:nth-child(even){
	background-color: rgba(244, 248, 252, 1);
}
.section-img{
	width: 622px;
	height: 514px;
}
.section-info{
	width: 578px;
	padding-top: 121px;
}
.section-block:nth-child(odd) .section-img{
	float: left;
}
.section-block:nth-child(odd) .section-info{
	float: left;
	padding-right: 59px;
}
.section-block:nth-child(odd) .section-info>*{
	float: right;
	text-align: right;
}
.section-block:nth-child(even) .section-img{
	float: right;
}
.section-block:nth-child(even) .section-info{
	float: left;
	padding-left: 61px;
}
.section-block:nth-child(even) .section-info>*{
	float: left;
	text-align: left;
}
.info-border{
	width: 46px;
	height: 8px;
	background: rgba(121, 221, 219, 1);
}
.info-title{
	width: 100%;
	color: rgba(13, 23, 38, 1);
	font-size: 26px;
	margin-top: 29px;
	font-weight: normal;
	line-height: 40px;
}
.info-text{
	width: 449px;
	margin-top: 21px;
	line-height: 27px;
	font-size: 14px;
	color: rgba(107, 109, 114, 1);
	height: 81px;
}
.use-btn{
	width:110px;
	height:36px;
	background:rgba(51,101,255,1);
	box-shadow:0px 3px 6px 0px rgba(109,141,206,0.3);
	text-align: center!important;
	line-height: 36px;
	color: #fff;
	font-size: 14px;
	margin-top: 45px;
	cursor: pointer;
}
.section3-main{
	height: 788px;
	position: relative;
	z-index: 2;
}

.charge-wrap{
	width: 900px;
	margin: 0 auto;
}
.charge-block{
	width: 420px;
	height: 720px;
	border-radius: 4px;
	overflow: hidden;
	float: left;
}
.charge-block:first-child{
	margin-right: 60px;
}
.charge-head{
	width: 100%;
	height: 140px;
	color: #fff;
	text-align: center;
	padding-top: 37px;
}
.charge-block:first-child .charge-head{
	background:linear-gradient(90deg,rgba(51,101,255,1) 0%,rgba(96,135,255,1) 100%);
}
.charge-block:last-child .charge-head{
	background:linear-gradient(90deg,rgba(139,161,193,1) 0%,rgba(169,187,211,1) 100%);
}
.version-name{
	font-size: 24px;
	color: #fff;
}
.version-price{
	margin-top: 10px;
}
.version-money{
	font-size: 30px;
}
.version-spec{
	font-size: 20px;
}
.charge-body{
	width: 100%;
	height: 580px;
	padding: 50px 54px 0 34px;
	border:2px solid rgba(235,237,239,1);
	border-top: none;
	background: #fff;
}
.charge-item{
	margin-bottom: 38px;
}
.charge-img{
	width: 36px;
	height: 36px;
	float: left;
	margin-top: 3px;
}
.charge-info{
	margin-left: 11px;
	float: left;
}
.charge-title{
	font-size: 18px;
	color: rgba(50, 50, 51, 1);
	line-height: 25px;
}
.charge-title-gray{
	color: rgba(139, 139, 139, 1);
}
.charge-text{
	width: 280px;
	font-size: 14px;
	color: rgba(157, 159, 159, 1);
	line-height: 21px;
	margin-top: 5px;
	margin-left: 1px;
}
.section3-bottom{
	height: 412px;
	background: url(../img/numberCardDistribution/bottom_bg.png);
	background-size: 1920px 100%;
	padding-top: 268px;
}
.use-btn2{
	width:140px;
	height:40px;
	background:rgba(51,101,255,1);
	text-align: center;
	color: #fff;
	font-size: 14px;
	line-height: 40px;
	margin: 0 auto;
	cursor: pointer;
}

